body {
  overflow: hidden;
}

/* 
  Hide direct images, some of these ad pixels appear to be broken and 
  therefore display as a broken image icon. 
*/
body > img {
  display: none;
}
